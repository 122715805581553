
import { Component, Vue } from 'vue-property-decorator'
import Map from './DetailMap.vue'
import List from './DetailList.vue'

@Component({
  name: 'WeatherQualityDetail',
  components: { Map, List }
})
export default class WeatherQualityDetail extends Vue {
  private radio = 'map'
  projectId = ''
  created () {
    this.radio = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
    this.projectId = this.$route.params.projectId as string
  }

  handleChange () {
    const path = `/weatherQualityDetail/detail${this.projectId}/${this.radio}`
    this.$router.replace({ path: path })
  }
}
